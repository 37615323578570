import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { v4 as generateUUID} from 'uuid';
import { message as antMessage } from 'antd';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config';

interface DashboardState {
  usersTotalCount: number,
  maleCount: number,
  femaleCount: number,
  isLoading: boolean
  usersPresentationsTotalCount: number
}
const initialState: DashboardState = {
  usersTotalCount: 0,
  maleCount: 0,
  femaleCount: 0,
  usersPresentationsTotalCount: 0,
  isLoading: false
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeDashboardStatistics: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
  },
});


export const getDashboardStatistics = (token: string): AppThunk => async (dispatch) => {
  const messageKey = generateUUID();
  
  try{
    dispatch(dashboardSlice.actions.changeDashboardStatistics({ isLoading: true }));
    message.loadingMessage('generating statistics ...', messageKey, 0);
    const { data : { results: { dashboardStatistcs } } } = await axios({
      method: 'GET',
      url: `${API_URL}/users/statistics/dashboard`,
      headers: {
        Authorization: token
      }
    });
    console.log(dashboardStatistcs)
    dispatch(dashboardSlice.actions.changeDashboardStatistics({ ...dashboardStatistcs }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    antMessage.destroy();
    dispatch(dashboardSlice.actions.changeDashboardStatistics({ isLoading: false }));
  }
};
export const { changeDashboardStatistics } = dashboardSlice.actions;

export const selectDashboardData = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
