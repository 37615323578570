import _ from 'lodash';

export default (topic: any) => {
  const operation = topic.interestId ? 'topic' : 'interest';
  const interest = _.pick(topic, ['isActive', 'icon',  ...(operation === 'topic' ? ['interestId'] : [])]);
  interest[operation] = topic.topic;
  interest.isActive = Boolean(topic.isActive);
  const translations = _.omit(topic,  ['isActive', 'topic', 'interestId', 'icon']);
  const formattedTranslations = Object.entries(translations).map(([key, value]) => ({
    languageId: key,
    [operation]: value
  })).filter((interest) => interest[operation]);
  
  return { [operation]: interest, translations: formattedTranslations, operation}
}