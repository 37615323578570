import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState } from './store';

interface User {
    bod: string
    created_at: string
    email: string
    firstName: string
    gender: string
    id: string
    isAgreedTerms: boolean
    isLoggedIn: boolean
    lastLoginAt: string
    lastName: string
    motherLanguage: string
    phoneNumber: string
    profilePicture: string | null
    role: string
    timeZone: string | null
    token: string
    updated_at: string
    watchedCount: number
}

const initialState: User = {
  bod: '',
  created_at: '',
  email: '',
  firstName: '',
  gender: '',
  id: '',
  isAgreedTerms: false,
  isLoggedIn: false,
  lastLoginAt: '',
  lastName: '',
  motherLanguage: '',
  phoneNumber: '',
  profilePicture: '', 
  role: '',
  timeZone: '', 
  token: localStorage.getItem('token') || '',
  updated_at: '',
  watchedCount: 0
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeCurrentUser: (state, action: PayloadAction<User>) => {
      localStorage.setItem('token', action.payload.token);
      return { ...state, ...action.payload } 
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    logout: (state, action: PayloadAction<any>) => {
      localStorage.removeItem('token');
      return { ...state, token: '' } 
    },
  },
});

export const { changeCurrentUser, logout } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentUserToken = (state: RootState) => state.user.token;
export const selectCurrentUser = (state: RootState) => state.user;
export const selectName = (state: RootState) => `${state.user.firstName} ${state.user.lastName}`;
export default userSlice.reducer;
