
interface Template {
  isImageOn: boolean | ''
  isOriginalLabel: boolean | ''
  isTargetLabel: boolean | ''
  isTargetSound: boolean | ''
  isOriginalSound: boolean | ''
  sleepTime: number
  originalLabelDurationstart: number,
  originalLabelDurationend: number,
  originalSoundDurationstart: number,
  targetSoundDurationstart: number,
  imageDurationstart: number,
  imageDurationend: number,
  targetLabelDurationstart: number,
  targetLabelDurationend: number,
}

export default (object: Template, changedObject: Template) => {
  const changedKeys = Object.entries(changedObject)
    .filter(([key, value]) => value || value === false || value === 0)
    .reduce((acc, [key, value]) => {
      if(object[key] !== value) return [...acc, key]
      else return acc;
    }, [] as string [])
  return changedKeys;
}
 