import jwt from 'jsonwebtoken';
export default (token : string | null) => {
  if(token) {
    const payload : any = jwt.decode(token);
    
    if(!payload || isTokenExpired(payload.exp)) return false;
    return true;
  }
  return false;
}


const isTokenExpired = (expirationTime) => {
  const currentTime = new Date().getTime() / 1000;
  return currentTime > parseInt(expirationTime, 10);
};