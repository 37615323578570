import React from 'react';
import { Dropdown, Menu, Avatar, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';


interface Props {
  logout: () => void
  url: string | null
  name: string
}

const menu = (logout: () => void) => (
  <Menu>
    <Menu.Item>
      <Button type="link" onClick={logout}>
        logout
      </Button>
    </Menu.Item>
  </Menu>
);
const DashBoardHeader = (props: Props) => {

    return (
      <div className="flex justify-end">
        <div className="pr-8">
          <Dropdown className="pt-4" overlay={menu(props.logout)} placement="bottomRight" >
             <Button
               onClick={(e: any) => e.preventDefault()}
               type="link"
              >
               <Avatar 
                  size="large"
                  src={props.url || undefined}>
                   {!props.url && props.name}
                </Avatar>
              <DownOutlined />
             </Button>
          </Dropdown>
        </div>
      </div>
    );
}

export default DashBoardHeader;