import jimp from 'jimp';
import _ from 'lodash';
import axios from 'axios';
import { uploadImageFile, generateSuggestedImagesTemplate } from './';
import { API_URL } from '../config';
import Logo from './12learn-logo.png';
interface Crop {
  unit: "px" | "%" | undefined
  x: number
  y: number
  width: number
  height: number
  aspect: number
}

type Markdown = 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | '';
interface Crop {
  unit: "px" | "%" | undefined
  x: number
  y: number
  width: number
  height: number
  aspect: number
}

interface Item {
  id: string
  suggestedImages: any[]
  originalLabel: string
  targetLabel: string
  originalLanguage: string
  originalAudio: string[]
  targetAudio: string[]
  originalAudioIndex: number
  targetAudioIndex: number
  interestId: string
  topicId: string
  suggestedImage: string
  targetLanguage: string
  markdown:'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  originalSequence: number
  targetSequence: number
  crop: Crop
  isPublished: boolean
  notes: string
  hasImage: boolean
  hasOriginalAudio: boolean
  hasTargetAudio: boolean
}
export default async(items: Item[], token: string) : Promise<any> => new Promise((resolve, reject) => {

  jimp.read(Logo).then(logoTpl => {
        
    return logoTpl.resize(65, 45);

  }).then(async(watermark) => {
    const combinePromises = items.map(async(item) => {
      const { crop } = item;
      const image = item.suggestedImages.find((suggImage) => suggImage.id === item.suggestedImage);
      const { assets: { preview: { url } } } = image;

      const { data: {results : { imageBuffer }}} = await axios({
        method: 'post',
        url: `${API_URL}/url/buffer`,
        data: { url }
      })
         .then(response => response);

      return jimp.read(Buffer.from(imageBuffer, 'base64'))
       .then(tpl => {
          const { x, y } = getTextPosition('top-left', crop);
          return tpl.composite(watermark, x, y, {
            mode: jimp.BLEND_SOURCE_OVER,
            opacityDest: 1,
            opacitySource: 0.3
          });
         })
    })
    const combinedImages = await Promise.all(combinePromises);
    const imagesFilesPromises = combinedImages.map((image) => {
      const { height, width } = image.bitmap;
      return image.getBufferAsync(image._originalMime).then((buffer) => {
      let wateredMarkImageFile= new File([buffer as BlobPart], `${new Date().getTime()}.jpg`, { type: 'image/jpg' });
      return { imageFile: wateredMarkImageFile, width, height };
    })
  });
  const images = await Promise.all(imagesFilesPromises);
  const fileUrlsPromises = images.map((image) => uploadImageFile(image.imageFile, token).then(markedImage => ({..._.omit(image, 'imageFile'), markedImage})));
  const urls = await Promise.all(fileUrlsPromises);
  const newItems = items.map((item, index) => {
    const { height, width, markedImage } = urls[index];
    return {
    ...item,
    markdown: 'top-left',
    suggestedImages: item.suggestedImages
    .map((image) => (image.id === item.suggestedImage) ? generateSuggestedImagesTemplate(item.suggestedImage, image.assets.preview.url, markedImage, width, height) : image),
   } 
  })
  resolve(newItems);
})
})
  

const getTextPosition = (markdown: Markdown, crop: Crop) => {
  const positions = {
    'bottom-left': {
       x: crop.x + 10,
       y: crop.y + crop.height - 40
      },
    'bottom-right': {
       x: crop.x + crop.width - 80,
       y:  crop.y + crop.height - 40
      },
    'top-right': {
       x: crop.x + crop.width - 80,
       y: crop.y + 10
      },
    'top-left': {
       x: crop.x + 10,
       y: crop.y + 10
      },
    '' : {
      x: 10,
      y: 220
    }
  }
  return positions[markdown];
}