import React from 'react';
import AppLayout from './components/AppLayout';
import NotAutherised from './routes/Unautherized';
import {  useSelector } from 'react-redux';
import { selectCurrentUserToken } from './app/userSlice';
import { isAuthenticated } from './utils/index';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-crop/dist/ReactCrop.css'


function App(props: any) {
  
  const token = useSelector(selectCurrentUserToken);

  return (
    <div className="App h-auto">
      <header className="App-header">
      </header>
      <div className="h-full flex justify-center align-center">
      {isAuthenticated(token) ? 
      <AppLayout />
       : <NotAutherised />}
      </div>
    </div>
  );
}

export default App;
