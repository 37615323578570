import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

interface Topic {
  id: string
  topic: string
  isActive: boolean
  icon: string
  index: number
}

interface Interest {
  id: string
  interest: string
  isActive: boolean
  icon: string
  index: number
}

interface Language {
  id: string
  language: string
  key: string
  flag: string
  isPublished: boolean
}

interface LoadingStatus {
  isLoading: boolean
}

interface Translation {
  id: string
  language: string
  key: string
  flag: string
  translation: string
}

interface TopicsState {
  topics: Topic[]
  interests: Interest[]
  languages: Language[]
  wordTranslations: Translation[]
  isLoading: boolean
  currentTopic: Topic
  currentInterest: Interest
}

const initalTopic = {
  id: "",
  topic: "",
  isActive: false,
  icon: "",
  index: 0
};

const initialInterest = {
  id: "",
  interest: "",
  isActive: false,
  icon: "",
  index: 0
};

const initialState: TopicsState = {
  topics: [],
  interests : [],
  languages : [],
  wordTranslations : [],
  isLoading: false,
  currentTopic: initalTopic,
  currentInterest: initialInterest
};

export const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    changeTopics: (state, action: PayloadAction<{ topics: Topic[] }>) => {
      return { ...state, ...action.payload } 
    },
    changeInterests: (state, action: PayloadAction<{ interests: Interest[] }>) => {
      return { ...state, ...action.payload } 
    },
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },

    // updateLanguage: (state, action: PayloadAction<{ language: Language }>) => {
    //   const { language } = action.payload;
    //   const languages = state.languages
    //     .map((lang: Language) => (lang.id === language.id) ? language : lang);
    //   return { ...state, languages, currentLanguage: initalLanguage } 
    // },
    // addLanguage: (state, action: PayloadAction<{ language: Language }>) => {
    //   const { language } = action.payload;
    //   const languages = [...state.languages, language];
    //   return { ...state, languages, currentLanguage: initalLanguage } 
    // },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeTopics, changeInterests, setLoadingStatus, changeState } = topicsSlice.actions;


export const getTopics =  async (interestId: string, token: string | null) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { topics } }} = await axios({
      method: 'GET',
      url: `${API_URL}/topics${interestId !== 'all' ? `?interestId=${interestId}` : ''}`,
      headers: {
        Authorization: token
      }
    });
    const formattedTopics = topics.map((topic: any, index: number) => ({
      ...topic,
      index
    }))
    return formattedTopics;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getInterests = async (token: string | null) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { interests } }} = await axios({
      method: 'GET',
      url: `${API_URL}/interests`,
      headers: {
        Authorization: token
      }
    });
    const formattedInterests = interests.map((interest: any, index: number) => ({
      ...interest,
      index
    }));

    return formattedInterests;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getLanguages = async () => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { languages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`
    });

    return languages;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};



export const getTopicsPageInitialData = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
    const [topics, interests, languages] = await Promise.all([getTopics('all', token), getInterests(token), getLanguages()]);
    dispatch(topicsSlice.actions.changeState({ topics, interests, languages }));
    message.successMessage('success..', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getInterestTopic = (interestId: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  try{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
    const topics = await getTopics(interestId, token);
    dispatch(topicsSlice.actions.changeState({ topics }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};


export const getWordTranslations = (word: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
    message.loadingMessage('loading translations...', messageKey, 0);
    const { data : { results: { translations: wordTranslations } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages/translations/word?word=${word}`,
      headers: {
        Authorization: token
      }
    });
    message.successMessage('translations loaded', messageKey);
    dispatch(topicsSlice.actions.changeState({ wordTranslations }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getTopicTranslations = (id: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
    message.loadingMessage('loading translations...', messageKey, 0);
    const { data : { results: { topicsTranslations } }} = await axios({
      method: 'GET',
      url: `${API_URL}/topics/${id}/translations`,
      headers: {
        Authorization: token
      }
    });
    message.successMessage('translations loaded', messageKey);
    const wordTranslations = topicsTranslations.map(({ languageId, topic }: any) => ({ id: languageId, translation: topic }));
    dispatch(topicsSlice.actions.changeState({ wordTranslations }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getInterestTranslations = (id: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
    message.loadingMessage('loading translations...', messageKey, 0);
    const { data : { results: { interestTranslation } }} = await axios({
      method: 'GET',
      url: `${API_URL}/interests/${id}/translations`,
      headers: {
        Authorization: token
      }
    });
    message.successMessage('translations loaded', messageKey);
    const wordTranslations = interestTranslation.map(({ languageId, interest }: any) => ({ id: languageId, translation: interest }));
    dispatch(topicsSlice.actions.changeState({ wordTranslations }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const addInterest = (topicData: any, topics: any, token: string): AppThunk => 
  async (dispatch) => {
    const isInterest = Boolean(topicData.interest);
    const messageKey = uuid();
    message.loadingMessage('adding interest...', messageKey, 0);
    try{
      dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
      const {data: { results }} = await axios({
        method: 'POST',
        url: `${API_URL}/${isInterest ? 'interests' : `interests/${topicData.interestId}/topic`}`,
        data: topicData,
        headers: {
          Authorization: token
        }
      });
      const newState = {
        wordTranslations: [],
        ...(isInterest && {interests: topics.concat(results['addedInterest'])}),
        ...(!isInterest && {topics: topics.concat(results['addedTopic'])})
      }
      dispatch(topicsSlice.actions.changeState(newState));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateTopic = (id: string, topicData: any, previousTopics: any, token: string): AppThunk => 
  async (dispatch) => {
    const isInterest = Boolean(topicData.interest);
    const messageKey = uuid();
    message.loadingMessage('updating...', messageKey, 0);
    try{
      dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results: { updatedTopic, wordTranslations } } } = await axios({
        method: 'PUT',
        url: `${API_URL}/interests/${isInterest ? id : topicData.topic.interestId}${isInterest ? '' : `/topic/${id}`}`,
        data: topicData,
        headers: {
          Authorization: token
        }
      });
      const topics = previousTopics.map((topic : Topic | Interest) => (topic.id === updatedTopic.id) ? { ...updatedTopic, index: topic.index } : topic);
      dispatch(topicsSlice.actions.changeState({ topics, wordTranslations }));
      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };


export const deleteTopic = (id: string, token: string, previousTopics: Topic[]): AppThunk => 
async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('deleting...', messageKey, 0);
  
  try{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { deletedId } }} = await axios.delete(`${API_URL}/topics/${id}`, {
      headers: {
        Authorization: token
      }
    });
    message.successMessage('deleted successfully', messageKey);
    const topics = previousTopics.filter((topic) => topic.id !== deletedId);
    dispatch(topicsSlice.actions.changeState({ topics }));
  } catch(err){
    message.errorMessage(err, messageKey);
  } finally{
    dispatch(topicsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};


export const selectTopics = (state: RootState) => state.topics;
// export const selectCurrentTopic = (state: RootState) => state.topics.currentTopic;

export default topicsSlice.reducer;
