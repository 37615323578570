import { v4 as uuid } from 'uuid';

export default (algorithimId: string, index: number) => ({
  id: uuid(),
  algorithimId,
  slideIndex: -1,
  currentIndex: index,
  isImageOn: true,
  isOriginalLabel: true,
  isTargetSound: true,
  isOriginalSound: true,
  isTargetLabel: true,
  sleepTime: 3000,
  originalLabelDuration: [0, 0],
  targetLabelDuration: [0, 0],
  originalSoundDuration: [0, 0],
  targetSoundDuration: [0, 0],
  imageDuration: [0, 0],
  originalLabelDurationstart: 0,
  originalLabelDurationend: 0,
  originalSoundDurationstart: 0,
  originalSoundDurationend: 0,
  targetSoundDurationstart: 0,
  targetSoundDurationend: 0,
  imageDurationstart: 0,
  imageDurationend: 0,
  targetLabelDurationstart: 0,
  targetLabelDurationend: 0,
  index
})