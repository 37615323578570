import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

interface Language {
  id: string
  language: string
  key: string
  flag: string
  isPublished: boolean
}

interface LoadingStatus {
  isLoading: boolean
}

interface LanguagesState {
  languages: Language[]
  isLoading: boolean
  currentLanguage: Language
}

const initalLanguage = {
  id: "",
  language: "",
  key: "",
  flag: "",
  isPublished: false
};

const initialState: LanguagesState = {
  languages : [],
  isLoading: false,
  currentLanguage: initalLanguage
};

export const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    changeLanguages: (state, action: PayloadAction<{ languages: Language[] }>) => {
      return { ...state, ...action.payload } 
    },
    updateLanguage: (state, action: PayloadAction<{ language: Language }>) => {
      const { language } = action.payload;
      const languages = state.languages
        .map((lang: Language) => (lang.id === language.id) ? language : lang);
      return { ...state, languages, currentLanguage: initalLanguage } 
    },
    addLanguage: (state, action: PayloadAction<{ language: Language }>) => {
      const { language } = action.payload;
      const languages = [...state.languages, language];
      return { ...state, languages, currentLanguage: initalLanguage } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeLanguages, setLoadingStatus } = languagesSlice.actions;


export const getLanguages = (token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(languagesSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { languages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`,
      headers: {
        Authorization: token
      }
    });
    const formattedLanguages = languages.map((language: any, index: number) => ({
      ...language,
      index
    }))
    dispatch(languagesSlice.actions.changeLanguages({ languages: formattedLanguages }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(languagesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};


export const updateLanguage = (id: string, language: Language, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('updating language...', messageKey, 0);
    try{
      dispatch(languagesSlice.actions.setLoadingStatus({ isLoading: true }));
      await axios({
        method: 'PUT',
        url: `${API_URL}/languages/${id}`,
        data: language,
        headers: {
          Authorization: token
        }
      });
      dispatch(languagesSlice.actions.updateLanguage({ language: { ...language, id } }));
      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(languagesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const addLanguage = (language: Language, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('adding language...', messageKey, 0);
    try{
      dispatch(languagesSlice.actions.setLoadingStatus({ isLoading: true }));
      const {data: { results: { addedLanguage } }} = await axios({
        method: 'POST',
        url: `${API_URL}/languages`,
        data: language,
        headers: {
          Authorization: token
        }
      });
      dispatch(languagesSlice.actions.addLanguage({ language: addedLanguage }));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(languagesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const selectLanguages = (state: RootState) => state.languages;
export const selectCurrentLanguage = (state: RootState) => state.languages.currentLanguage;

export default languagesSlice.reducer;
