import _ from 'lodash';
interface Item {
  id: string
  suggestedImages: any[]
  originalLabel: string
  targetLabel: string
  originalLanguage: string
  originalAudio: string[]
  targetAudio: string[]
  originalAudioIndex: number
  targetAudioIndex: number
  interestId: string
  topicId: string
  suggestedImage: string
  targetLanguage: string
  markdown:'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  originalSequence: number
  targetSequence: number
  isPublished: boolean
  notes: string
  hasImage: boolean
  hasOriginalAudio: boolean
  hasTargetAudio: boolean
}

export default (items: Item[], originalLanguage: string,  targetLanguage: string) => {
  const formatted = items.map((item: Item) => {
    const image = item.suggestedImages.find((image: any) => item.suggestedImage === image.id);
    return {
      ..._.omit(item, ['suggestedImages', 'suggestedImage', 'searchText', 'page']),
      image,
      markdown: item.markdown || 'top-left',
      targetLanguage,
      originalLanguage
    }
  });

  return formatted;
}