
interface Template {
  id: string
  algorithimId: string
  slideIndex: number
  currentIndex: number
  isImageOn: boolean
  isOriginalLabel: boolean
  isTargetLabel: boolean
  isTargetSound: boolean
  isOriginalSound: boolean
  sleepTime: number
  originalLabelDuration: [number, number]
  targetLabelDuration: [number, number]
  targetSoundDuration: [number, number]
  originalSoundDuration: [number, number]
  imageDuration: [number, number]
}


export default (template: Template) => {
  const { targetSoundDuration, originalSoundDuration, isOriginalSound, isTargetSound } = template;
  let first
  let gap = 0;
  if(isOriginalSound && isTargetSound) {
    first = (targetSoundDuration[0] - originalSoundDuration[0] > 0) ? 'originalAudio' : 'targetAudio';
    gap = Math.abs(targetSoundDuration[0] - originalSoundDuration[0]);
  } else if (isOriginalSound && !isTargetSound) {
    first='originalAudio';
  } else if( !isOriginalSound && isTargetSound){
    first='targetAudio';
  }
  return { first, gap };
}