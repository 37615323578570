import axios from 'axios';
import { API_URL } from '../config'; 


export default async (file : File, token: string) => {
  let data = new FormData();
  data.append('file', file);

  const { data: { results : { fileUrl }}} = await axios({
    method: 'POST',
    url: `${API_URL}/upload/file`,
    data,
    headers: {
      Authorization: token
    }
  });
  return fileUrl;
}