import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import _ from 'lodash';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

interface Algorithm {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  isActive: boolean
  isPublished: boolean
}

interface LoadingStatus {
  isLoading: boolean
}

interface Rule {
  id: string
  algorithmContainerId: string
  algorithmtoFire: string
  order: string
  daysStart: number
  daysEnd?: number
  algorithmCompletionCondition: string
  login: 'First login'| 'Within'| 'More than' | string
  isLastRule: boolean
  createdAt: string
  updatedAt: string
}

interface rulesState {
  algorithms: Algorithm[]
  rules: Rule[]
  isLoading: boolean
}

const initialState: rulesState = {
  algorithms : [],
  rules : [],
  isLoading: false,
};

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    changeRulesState: (state, action: PayloadAction<{ algorithms?: Algorithm[], rules?: Rule[] }>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeRulesState, setLoadingStatus } = rulesSlice.actions;

export const getAlgorithms = async (algorithmContainerId: string, token: string | null) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { algorithims } }} = await axios({
      method: 'GET',
      url: `${API_URL}/algorithims-containers/${algorithmContainerId}/algorithims`,
      headers: {
        Authorization: token
      }
    });
    const formattedInterests = algorithims.map((algorithm: any, index: number) => ({
      ...algorithm,
      index
    }));

    return formattedInterests;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getRules = async (algorithmContainerId: string, token: string) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { rules } }} = await axios({
      method: 'GET',
      url: `${API_URL}/algorithims-containers/${algorithmContainerId}/rules`,
      headers: {
        Authorization: token
      }
    });

    return rules;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getInitialData = (algorithmContainerId: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try{
    dispatch(rulesSlice.actions.setLoadingStatus({ isLoading: true }));
    const [rules, algorithms] = await Promise.all([getRules(algorithmContainerId, token), getAlgorithms(algorithmContainerId, token)]);
    const formattedRules = rules.map((rule: any) => 
      ({
        ..._.omit(rule, ['updated_at', 'created_at']),
        createdAt: rule.created_at,
        updatedAt: rule.updated_at
      })
    );
    dispatch(rulesSlice.actions.changeRulesState({ rules: formattedRules, algorithms }));
    message.successMessage('success..', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(rulesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
}
export const updateRules = (algorithmContainerId: string, rules: any, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    const formattedRules = rules.map((rule) => ({ ...rule, isLastRule: parseInt(rule.order, 10) === rules.length }));
    console.log(formattedRules)
    message.loadingMessage('updating algorithim container...', messageKey, 0);
    try{
      dispatch(rulesSlice.actions.setLoadingStatus({ isLoading: true }));
      await axios({
        method: 'PUT',
        url: `${API_URL}/algorithims-containers/${algorithmContainerId}/rules`,
        data: { rules: formattedRules },
        headers: {
          Authorization: token
        }
      });

      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(rulesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const selectRules = (state: RootState) => state.rules;

export default rulesSlice.reducer;
