import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { message as antMessage } from 'antd';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

interface Algorithim {
    id: string
    title: string
    createdAt: string
    updatedAt: string
    isActive: boolean
    isPublished: boolean
}

interface LoadingStatus {
  isLoading: boolean
}

interface AlgorithimsState {
  algorithims: Algorithim[]
  isLoading: boolean
}


const initialState: AlgorithimsState = {
  algorithims : [],
  isLoading: false,
};

export const algorithimsSlice = createSlice({
  name: 'algorithims',
  initialState,
  reducers: {
    changeAlgorithims: (state, action: PayloadAction<{ algorithims: Algorithim[] }>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeAlgorithims, setLoadingStatus } = algorithimsSlice.actions;


export const getAlgorithims = (id: string, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(algorithimsSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { algorithims } }} = await axios({
      method: 'GET',
      url: `${API_URL}/algorithims-containers/${id}/algorithims`,
      headers: {
        Authorization: token
      }
    });
    const formattedALgorithims = algorithims.map((algorithim: any, index: number) => ({
      ...algorithim,
      createdAt: moment(algorithim.created_at).format('L'),
      updatedAt: moment(algorithim.updated_at).format('L'),
      index
    }))
    dispatch(algorithimsSlice.actions.changeAlgorithims({ algorithims: formattedALgorithims }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(algorithimsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const addAlgorithm = (algorithm: any, algorithmContainerId: string, addedAlgorithms: Algorithim[] ,token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    message.loadingMessage('adding algorithm...', messageKey, 0);
    try{
      dispatch(algorithimsSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { addedAlgorithm } } } = await axios({
        method: 'POST',
        url: `${API_URL}/algorithims-containers/${algorithmContainerId}/algorithims`,
        data: algorithm,
        headers: {
          Authorization: token
        }
      });
      const formatted = {
        ...addedAlgorithm,
        createdAt: moment(addedAlgorithm.created_at).format('L'),
        updatedAt: moment(addedAlgorithm.updated_at).format('L'),
        index: addedAlgorithms.length + 1
      }
      dispatch(algorithimsSlice.actions.changeAlgorithims({ algorithims: [...addedAlgorithms, formatted]}));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(algorithimsSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateAlgorithm = (id: string, algorithmContainerId: string, algorithm: any, addedAlgorithms: Algorithim[], token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    message.loadingMessage('updating algorithim container...', messageKey, 0);
    try{
      dispatch(algorithimsSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { updatedAlgorithm } } } = await axios({
        method: 'PUT',
        url: `${API_URL}/algorithims-containers/${algorithmContainerId}/algorithims/${id}`,
        data: algorithm,
        headers: {
          Authorization: token
        }
      });


      const formatted = {
        ...updatedAlgorithm,
        createdAt: moment(updatedAlgorithm.created_at).format('L'),
        updatedAt: moment().format('L'),
      }
      const newUpdatedAlgorithms = addedAlgorithms
        .map((addedALgorithm, index) => addedALgorithm.id === id ? { ...formatted, index } : addedALgorithm);

      dispatch(algorithimsSlice.actions.changeAlgorithims({ algorithims: newUpdatedAlgorithms}));
      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(algorithimsSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };


export const downloadAlgorithmSampleFile = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('exporting sample file..', messageKey, 0);
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/algorithms/download/sample`,
      responseType: 'blob',
      headers: {
        Authorization: token
      },
    });
    fileDownload(data, `Algorithm Sample.xlsx`);
    antMessage.destroy();
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};


export const selectAlgorithims = (state: RootState) => state.algorithims;

export default algorithimsSlice.reducer;
