import jimp from 'jimp';
import axios from 'axios';
import { API_URL } from '../config';
import Logo from './12learn-logo.png';

type Markdown = 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | '';
interface Crop {
  unit: "px" | "%" | undefined
  x: number
  y: number
  width: number
  height: number
  aspect: number
}
export default async(url: string, markdown: Markdown, targetLabel: string, crop: Crop) : Promise<any> => new Promise(async(resolve, reject) => {
  const { data: {results : { imageBuffer }}} = await axios({
    method: 'post',
    url: `${API_URL}/url/buffer`,
    data: { url }
  })
     .then(response => response);

  jimp.read(Buffer.from(imageBuffer, 'base64'))
  .then(tpl => (
    jimp.read(Logo).then(logoTpl => {
      
      let watermark = logoTpl.resize(65, 45);
      const { x, y } = getTextPosition(markdown, crop);
      return tpl.composite(watermark, x, y, {
        mode: jimp.BLEND_SOURCE_OVER,
        opacityDest: 1,
        opacitySource: 0.3
      });
    })))
    .then(image => {
      const { height, width } = image.bitmap;
      return image.getBufferAsync(image._originalMime).then((buffer) => {
      let wateredMarkImageFile= new File([buffer as BlobPart], `${targetLabel}.jpg`, { type: 'image/jpg' });
      resolve({imageFile: wateredMarkImageFile, width, height});
      })
    })
      .catch((err) => {
        reject(err)
        reject({ message: 'failed to read image' })
      })
});

const getTextPosition = (markdown: Markdown, crop: Crop) => {
  const positions = {
    'bottom-left': {
       x: crop.x + 10,
       y: crop.y + crop.height - 40
      },
    'bottom-right': {
       x: crop.x + crop.width - 80,
       y:  crop.y + crop.height - 40
      },
    'top-right': {
       x: crop.x + crop.width - 80,
       y: crop.y + 10
      },
    'top-left': {
       x: crop.x + 10,
       y: crop.y + 10
      },
    '' : {
      x: 10,
      y: 220
    }
  }
  return positions[markdown];
}

