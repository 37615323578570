import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom';
import { Spin } from 'antd';

const LoginPage = lazy(() => import( '../pages/login/loginContainer'));

const NotAuthorized = () => (
  <Suspense fallback={<Spin spinning />}>
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/*">
          <Redirect to="/" />
       </Route>
      </Switch>
    </Router>
  </Suspense>
);

export default NotAuthorized;
