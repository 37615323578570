export default (id: string, url: string, markedImage?: string, width?: number, height?: number) => ({
  id,
  media_type: 'image',
  isOwned: 0.6667,
  assets: {
    huge_thumb: {
      height: 260,
      url,
      markedImage,
      width: 173
    },
    large_thumb: {
      height: 150,
      url,
      markedImage,
      width: 100
    },
    preview: {
      height: height || 300,
      url,
      markedImage,
      width: width || 450
    },
    preview_1000: {
      height: 1000,
      url,
      markedImage,
      width: 667
    },
    preview_1500: {
      height: 1500,
      url,
      markedImage,
      width: 1000
    },
    small_thumb: {
      height: 100,
      url,
      markedImage,
      width: 67
    }
  },
  description: '',
  has_model_release: true,
  image_type: 'photo',
  isUploaded: true
});