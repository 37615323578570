
import { message } from 'antd';

type error = {
  value: string
  msg: string
  param: string
  location: string
  }

const errorMessage = (error: any, key: string) => {
  const content = error.respnse ? error.response.data.errors.reduce((acc: string, error: error) =>  acc + error.msg, '') : error.message;
  message.error({
    content,
    key
  });
};

const loadingMessage = (content: string, key: string, duration: number) => {
  message.loading({
    content,
    key,
    duration
  });
};

const successMessage = (content: string, key : string) => {
  message.success({
    content,
    key
  });
};

const infoMessage = (content: string, key : string) => {
  message.info({
    content,
    key
  });
};

export default {
  errorMessage,
  loadingMessage,
  successMessage,
  infoMessage
}