import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config';


interface Slide {
  id: string
  image?: any
  originalLabel: string
  targetLabel: string
  originalLanguage: string
  targetLanguage: string
  originalAudio: string[]
  targetAudio: string[]
  interestId: string
  originalAudioIndex: number
  targetAudioIndex: number
  topicId: string
  suggestedImage: string
  markdown: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  originalSequence:number
  targetSequence:number
  originalLang?:string
  targetLang?:string
  isPublished: boolean
}

interface User {
    bod: string
    created_at: string
    email: string
    firstName: string
    gender: string
    id: string
    isAgreedTerms: boolean
    isLoggedIn: boolean
    lastLoginAt: string
    lastName: string
    motherLanguage: string
    phoneNumber: string
    profilePicture: string | null
    role: string
    timeZone: string | null
    updated_at: string
    watchedCount: number
    learnedSlides: Slide[]
}

interface Pagination {
  current: number
  pageSize: number
  total: number
}

interface LoadingStatus {
  isLoading: boolean
}


interface Language {
  id: string
  language: string
  key: string
  flag: string
  isPublished: boolean
}
interface UsersState {
  list: [User] | []
  isLoading: boolean
  pagination: Pagination
  languages: Language[]
}


const initialState: UsersState = {
  list : [],
  isLoading: false,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  languages: []
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeUsers: (state, action: PayloadAction<UsersState>) => {
      return { ...state, ...action.payload } 
    },
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeUsers, setLoadingStatus } = userSlice.actions;


export const getUsers = (pagination : Pagination, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(userSlice.actions.changeState({ isLoading: true, list: [] }));
    const { data : { results: { users } }} = await axios({
      method: 'GET',
      url: `${API_URL}/users?limit=${pagination.pageSize}&offset=${pagination.current}`,
      headers: {
        Authorization: token
      }
    });
    console.log(users)
    dispatch(userSlice.actions.changeState(users));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(userSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};


export const getLanguages = (): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  try{
    message.loadingMessage('loading initial data..', messageKey, 0);
    dispatch(userSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { languages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`
    });
    dispatch(userSlice.actions.changeState({ languages }));
    message.successMessage('success..', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(userSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsers = (state: RootState) => state.users;
export const selectUsersPagination = (state: RootState) => state.users.pagination;
export const selectLanguages = (state: RootState) => state.users.languages;

export default userSlice.reducer;
