import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { message as antMessage } from 'antd';
import { v4 as uuid} from 'uuid';
import moment from 'moment';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config';

interface Video {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  createdBy: string
  description: string
}

interface Tag {
  id: string
  tag: string
}

interface LoadingStatus {
  isLoading: boolean
}

interface VideosState {
  videos: Video[]
  total: number
  pageSize: number
  tags: Tag[]
  isLoading: boolean
  preSignedUrl: string
  fileKey: string
  acceptedFile: any
}


const initialState: VideosState = {
  videos: [],
  tags:[],
  preSignedUrl: '',
  fileKey: '',
  acceptedFile: null,
  isLoading: false,
  total: 0,
  pageSize: 10
};

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeState, setLoadingStatus } = videosSlice.actions;


export const getVideos = (token: string | null, pageSize: number, pageNumber: number, query: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { videos, total } }} = await axios({
      method: 'GET',
      url: `${API_URL}/videos/?${query}&limit=${pageSize}&offset=${pageNumber}`,
      headers: {
        Authorization: token
      }
    });
    const formattedVideos = videos.map((video: any, index: number) => ({
      ...video,
      createdAt: moment(video.created_at).format('L'),
      updatedAt: moment(video.updated_at).format('L'),
    }))
    dispatch(videosSlice.actions.changeState({ videos: formattedVideos, total }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getTags = (token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { tags } }} = await axios({
      method: 'GET',
      url: `${API_URL}/tags`,
      headers: {
        Authorization: token
      }
    });
    dispatch(videosSlice.actions.changeState({ tags }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getPreSignedUrl = (type: string, file: any, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { preSignedUrl, fileKey }  }} = await axios({
      method: 'GET',
      url: `${API_URL}/videos/presignedurl?fileExtension=${type}`,
      headers: {
        Authorization: token
      }
    });
    dispatch(videosSlice.actions.changeState({ preSignedUrl, acceptedFile: file, fileKey }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const onFinishForm = (videos: any, file: any, url: string, fileKey: string, title: string, description: string, tags: string, setProgress: any, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('uploading and saving..', messageKey, 0);
  
  try{
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: true }));
    await axios({
      method: 'PUT',
      url,
      data: file,
      onUploadProgress: progressEvent => setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    });
    const {data: { results: { addedVideo, newTags }}} = await axios({
      method: 'POST',
      url: `${API_URL}/videos/title/${title || file.name}/description/${description || 'empty'}/tags/${tags || 'empty'}/upload?fileKey=${fileKey}`,
      headers: {
        Authorization: token
      },
    })
    const formattedVideos = [addedVideo, ...videos].map((video, index) => 
    ({
      ...video,
      createdAt: moment(video.created_at).format('L'),
      updatedAt: moment(video.updated_at).format('L'),
      index
    })
    )
    dispatch(changeState({ videos: formattedVideos, tags: newTags }))
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    antMessage.destroy();
    dispatch(videosSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const selectVideos = (state: RootState) => state.videos;

export default videosSlice.reducer;
