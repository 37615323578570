import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import moment from 'moment';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'


interface Interest {
  id: string
  interest: string
  isActive: boolean
  icon: string
  index: number
};

interface Language {
  id: string
  language: string
  key: string
  flag: string
  isPublished: boolean
};

interface AlgorithmsContainersLanguages {
  languageId: string
  algorithmContainerId: string
}

interface AlgorithmsContainersInterests {
  interestId: string
  algorithmContainerId: string
}

interface AlgorithimContainer {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  isUsed: boolean
  notes: string
  isPublished: boolean
  languages: AlgorithmsContainersLanguages[]
  interests: AlgorithmsContainersInterests[]
};


interface LoadingStatus {
  isLoading: boolean
};

interface AlgorithimsContainersState {
  algorithimsContainers: AlgorithimContainer[]
  isLoading: boolean
  interests: Interest[]
  languages: Language[]
};

const initialState: AlgorithimsContainersState = {
  algorithimsContainers : [],
  isLoading: false,
  interests : [],
  languages : [],
};

export const algorithimsContainersSlice = createSlice({
  name: 'algorithimsContainers',
  initialState,
  reducers: {
    changeAlgorithimsContainers: (state, action: PayloadAction<{ algorithimsContainers: AlgorithimContainer[] }>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeAlgorithimsContainers, setLoadingStatus } = algorithimsContainersSlice.actions;


export const getAlgorithimsContainers = (token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { algorithimsContainers } }} = await axios({
      method: 'GET',
      url: `${API_URL}/algorithims-containers`,
      headers: {
        Authorization: token
      }
    });
    const formattedALgorithims = algorithimsContainers.map((algorithim: any, index: number) => ({
      ...algorithim,
      createdAt: moment(algorithim.created_at).format('L'),
      updatedAt: moment(algorithim.updated_at).format('L'),
      index: index + 1
    }))
    dispatch(algorithimsContainersSlice.actions.changeAlgorithimsContainers({ algorithimsContainers: formattedALgorithims }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getInterests = async (token: string | null) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { interests } }} = await axios({
      method: 'GET',
      url: `${API_URL}/interests`,
      headers: {
        Authorization: token
      }
    });
    const formattedInterests = interests.map((interest: any, index: number) => ({
      ...interest,
      index
    }));

    return formattedInterests;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getLanguages = async () => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { languages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`
    });

    return languages;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getLanguagesAndTopics = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try{
    dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: true }));
    const [interests, languages] = await Promise.all([ getInterests(token), getLanguages()]);
    dispatch(algorithimsContainersSlice.actions.changeState({ interests, languages }));
    message.successMessage('success..', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const addAlgorithmContainer = (algorithmContainer: any, addedAlgorithmsContainers: AlgorithimContainer[] ,token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    if(!algorithmContainer.languages.length)
      return message.infoMessage('languages are required', messageKey);
    if(!algorithmContainer.interests.length)
      return message.infoMessage('interests are required', messageKey);
    
    message.loadingMessage('adding algorithm container...', messageKey, 0);
    try{
      dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { addedAlgorithmContainer } } } = await axios({
        method: 'POST',
        url: `${API_URL}/algorithims-containers`,
        data: algorithmContainer,
        headers: {
          Authorization: token
        }
      });
      const formatted = {
        ...addedAlgorithmContainer,
        createdAt: moment(addedAlgorithmContainer.created_at).format('L'),
        updatedAt: moment(addedAlgorithmContainer.updated_at).format('L'),
        index: addedAlgorithmsContainers.length + 1
      }
      dispatch(algorithimsContainersSlice.actions.changeState({ algorithimsContainers: [...addedAlgorithmsContainers, formatted]}));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateAlgorithmContainer = (id: string, algorithmContainer: any, addedAlgorithmsContainers: AlgorithimContainer[], token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    if(!algorithmContainer.languages.length)
      return message.infoMessage('languages are required', messageKey);
    if(!algorithmContainer.interests.length)
      return message.infoMessage('interests are required', messageKey);
    
    message.loadingMessage('updating algorithim container...', messageKey, 0);
    try{
      dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { updatedAlgorithmContainer } } } = await axios({
        method: 'PUT',
        url: `${API_URL}/algorithims-containers/${id}`,
        data: algorithmContainer,
        headers: {
          Authorization: token
        }
      });
      const formatUpdatedAlgorithm = {
        ...updatedAlgorithmContainer.algorithmContainer,
         interests: updatedAlgorithmContainer.interests,
         languages: updatedAlgorithmContainer.languages,
         updatedAt: moment().format('L'),
      };
      const newAddedAlgorithmsContainers = addedAlgorithmsContainers
        .map((addedALgorithmContainer) => addedALgorithmContainer.id === id ? {...addedALgorithmContainer, ...formatUpdatedAlgorithm} : addedALgorithmContainer);

      dispatch(algorithimsContainersSlice.actions.changeState({ algorithimsContainers: newAddedAlgorithmsContainers}));
      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(algorithimsContainersSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const selectAlgorithimsContainers = (state: RootState) => state.algorithimsContainers;

export default algorithimsContainersSlice.reducer;
