import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'
import { ReactText } from 'react';

interface Template {
  id: string
  algorithimId: string
  index: number
  slideIndex: number
  currentIndex: number
  isImageOn: boolean
  isTargetLabel: boolean
  isOriginalLabel: boolean
  isTargetSound: boolean
  isOriginalSound: boolean
  sleepTime: number
  originalLabelDuration: [number, number]
  targetLabelDuration: [number, number]
  imageDuration: [number, number]
  originalLabelDurationstart: number,
  originalLabelDurationend: number,
  targetSoundDuration: [number, number]
  originalSoundDuration: [number, number]
  originalSoundDurationstart: number
  originalSoundDurationend: number
  targetSoundDurationstart: number
  targetSoundDurationend: number
  imageDurationstart: number,
  imageDurationend: number,
  targetLabelDurationstart: number,
  targetLabelDurationend: number,
}

interface Slide {
  id: string
  suggestedImages: any[]
  originalLabel: string
  targetLabel: string
  originalLanguage: string
  targetLanguage: string
  originalAudio: string[]
  originalAudioIndex: number
  targetAudioIndex: number
  targetAudio: string[]
  interestId: string
  topicId: string
  suggestedImage: string
  markdown:'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  originalSequence: number
  targetSequence: number
  originalLang?: string
  targetLang?: string
  isPublished: boolean
}

interface Topic {
  id: string
  topic: string
  isActive: boolean
  icon: string
  index: number
}

interface Interest {
  id: string
  interest: string
  isActive: boolean
  icon: string
  index: number
}

interface Language {
  id: string
  language: string
  key: string
  flag: string
  isPublished: boolean
}


interface Id {
  id: string
}

interface LoadingStatus {
  isLoading: boolean
}

interface TemplatesState {
  templates: Template[]
  topics: Topic[]
  interests: Interest[]
  languages: Language[]
  isLoading: boolean
  slidesToPreview: Slide[],
  currentTemplate: number
}

const initialState: TemplatesState = {
  templates : [],
  topics: [],
  interests : [],
  languages : [],
  isLoading: false,
  slidesToPreview: [],
  currentTemplate: 0
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    changeTemplates: (state, action: PayloadAction<{ templates: any[] }>) => {
      return { ...state, ...action.payload } 
    },
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
    formatAfterSaveTemplate: (state, action: PayloadAction<{ template: Template }>) => {
      const { template } = action.payload;
      const templates = state.templates
       .map((item: Template) => (item.id === template.id) ? template : item);

      return { ...state, templates };
    },
    deleteById: (state, action: PayloadAction<Id>) => {
      const templateToDeleteIndex = state.templates.find((template) => template.id === action.payload.id)?.currentIndex;
      if(templateToDeleteIndex) {
        const templates = state.templates
        .filter((item: Template) => action.payload.id !== item.id)
        .map((template) => template.currentIndex > templateToDeleteIndex ? {...template, currentIndex: template.currentIndex - 1} : template);
        return { ...state, templates}
      }
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeTemplates, setLoadingStatus, changeState } = templatesSlice.actions;

export const getTopics =  async (interestId: string, token: string | null) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { topics } }} = await axios({
      method: 'GET',
      url: `${API_URL}/topics${interestId !== 'all' ? `?interestId=${interestId}` : ''}`,
      headers: {
        Authorization: token
      }
    });
    const formattedTopics = topics.map((topic: any, index: number) => ({
      ...topic,
      index
    }))
    return formattedTopics;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getInterests = async (token: string | null) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { interests } }} = await axios({
      method: 'GET',
      url: `${API_URL}/interests`,
      headers: {
        Authorization: token
      }
    });
    const formattedInterests = interests.map((interest: any, index: number) => ({
      ...interest,
      index
    }));

    return formattedInterests;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getLanguages = async () => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { languages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`
    });

    return languages;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getFiltersinitailData = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try{
    dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: true }));
    const [topics, interests, languages] = await Promise.all([getTopics('all', token), getInterests(token), getLanguages()]);
    dispatch(templatesSlice.actions.changeState({ topics, interests, languages }));
    message.successMessage('success..', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getSuggestedSlides = (data: any, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  const { 
    interest,
    originalLanguage,
    targetLanguage,
     topic
  } = data;

  message.loadingMessage('loading slides..', messageKey, 0);
  try{
    const { data: {results : { suggestedSlides }}} = await axios({
      method: 'GET',
      url: `${API_URL}/slides/suggested/${interest}/${topic}/${targetLanguage}/${originalLanguage}`,
      data: { data },
      headers: {
        Authorization: token
      },
    });
    if(suggestedSlides.length < 10 ) {
      throw new Error('slides count was not fulfilled')
    } else {
      message.successMessage('suggested Slides Loaded..', messageKey);
      const slidesToPreview =  suggestedSlides
      .sort((a: any, b: any) => parseInt(a.targetSequence.replace(',', '')) - parseInt(b.targetSequence.replace(',', '')));
      dispatch(templatesSlice.actions.changeState({ slidesToPreview }));
    }
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};


export const getTemplates = (algorithimId: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { templates } }} = await axios({
      method: 'GET',
      url: `${API_URL}/algorithims/${algorithimId}/templates`,
      headers: {
        Authorization: token
      }
    });
    const formattedTemplates = templates.map((algorithim: any, index: number) => ({
      originalLabelDurationstart: algorithim.originalLabelDuration[0],
      originalLabelDurationend: algorithim.originalLabelDuration[1],
      originalSoundDurationstart: algorithim.originalSoundDuration[0],
      originalSoundDurationend: algorithim.originalSoundDuration[1],
      targetSoundDurationstart: algorithim.targetSoundDuration[0],
      targetSoundDurationend: algorithim.targetSoundDuration[1],
      imageDurationstart: algorithim.imageDuration[0],
      imageDurationend: algorithim.imageDuration[1],
      targetLabelDurationstart: algorithim.targetLabelDuration[0],
      targetLabelDurationend: algorithim.targetLabelDuration[1],
      ...algorithim,
      index
    }))
    .sort((a: any, b: any) => parseInt(a.currentIndex) - parseInt(b.currentIndex));


    dispatch(templatesSlice.actions.changeTemplates({ templates: formattedTemplates }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const saveTemplates = (template: any, index: number | undefined, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('saving...', messageKey, 0);
    
    try{
      dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: true }));
       await axios({
        method: 'PUT',
        url: `${API_URL}/algorithims/${template.algorithimId}/templates/${template.id}/save`,
        data: template,
        headers: {
          Authorization: token
        }
      });
      const formattedTemplate = {
        originalLabelDurationstart: template.originalLabelDuration[0],
        originalLabelDurationend: template.originalLabelDuration[1],
        originalSoundDurationstart: template.originalSoundDuration[0],
        originalSoundDurationend: template.originalSoundDuration[1],
        targetSoundDurationstart: template.targetSoundDuration[0],
        targetSoundDurationend: template.targetSoundDuration[1],
        imageDurationstart: template.imageDuration[0],
        imageDurationend: template.imageDuration[1],
        targetLabelDurationstart: template.targetLabelDuration[0],
        targetLabelDurationend: template.targetLabelDuration[1],
        index,
        ...template,
      }
      dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: false }));
      message.successMessage('saved successfully', messageKey);
      dispatch(templatesSlice.actions.formatAfterSaveTemplate({ template: formattedTemplate }));
    } catch(err) {
      message.errorMessage(err, messageKey);
      dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const saveTemplatesBulk = (templatesToUpdate: ReactText[], updatedTemplates: any[], currentTemplates: Template[], algorithmId: string, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('saving...', messageKey, 0);
    
    try{
      dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: true }));
       await axios({
        method: 'PUT',
        url: `${API_URL}/algorithims/${algorithmId}/templates/${JSON.stringify(templatesToUpdate)}/save/bulk`,
        data: { updatedTemplates },
        headers: {
          Authorization: token
        }
      });
      const templates = currentTemplates
        .map((template) => {
          const updatedTemplate = updatedTemplates.find((t) => t.id === template.id);
          return templatesToUpdate.includes(template.id) ? {
          ...template,
           ...updatedTemplate,
           originalLabelDurationstart: updatedTemplate.originalLabelDuration[0],
           originalLabelDurationend: updatedTemplate.originalLabelDuration[1],
           originalSoundDurationstart: updatedTemplate.originalSoundDuration[0],
           originalSoundDurationend: updatedTemplate.originalSoundDuration[1],
           targetSoundDurationstart: updatedTemplate.targetSoundDuration[0],
           targetSoundDurationend: updatedTemplate.targetSoundDuration[1],
           imageDurationstart: updatedTemplate.imageDuration[0],
           imageDurationend: updatedTemplate.imageDuration[1],
           targetLabelDurationstart: updatedTemplate.targetLabelDuration[0],
           targetLabelDurationend: updatedTemplate.targetLabelDuration[1],
        } : template
      });

      message.successMessage('saved successfully', messageKey);
      dispatch(templatesSlice.actions.changeTemplates({ templates }));
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };


export const deleteTemplate = (algorithimId: string, id: string, token: string): AppThunk => 
async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('deleting slide...', messageKey, 0);
  
  try{
    dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: true }));
    await axios.delete(`${API_URL}/algorithims/${algorithimId}/templates/${id}`, {
      headers: {
        Authorization: token
      }
    });
    message.successMessage('deleted successfully', messageKey);
    dispatch(templatesSlice.actions.deleteById({ id }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(templatesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const selectTemplates = (state: RootState) => state.templates;

export default templatesSlice.reducer;
