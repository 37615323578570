import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

interface LoadingStatus {
  isLoading: boolean
}

interface Language {
  id: string
  language: string
  key: string
  flag: string
  isPublished: boolean
}

interface record {
  title: string
  value: number
  color: string
}

interface ImportUUIDSState {
  isLoading: boolean
  languages: Language[]
  pieChartData: record[]
}



const initialState: ImportUUIDSState = {
  isLoading: false,
  languages: [],
  pieChartData: []
};

export const importUUIDSSlice = createSlice({
  name: 'importUUIDS',
  initialState,
  reducers: {
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
  },
});
export const importUUIDS = (imagesPath: string, audiosPath: string, folderDataLanguage: string, from: string, to: string, toLanguage: string, token: string): AppThunk =>
 async (dispatch) => {
  const messageKey = uuid();
  if(from === to)
    return message.infoMessage('convertion can not be the same', messageKey);
  if(folderDataLanguage === toLanguage)
    return message.infoMessage('can not be the same languages', messageKey);

  
  try{
    dispatch(importUUIDSSlice.actions.setLoadingStatus({ isLoading: true }));
    message.loadingMessage('importing slides ,, this may take a while', messageKey, 0);
    const { data : { results: { invalidData, insertedSlides }}}= await axios({
      method: 'post',
      url: `${API_URL}/import-uuids`,
      headers: {
        Authorization: token
      },
      data:{
        imagesPath,
        audiosPath,
        folderDataLanguage,
        from,
        to,
        toLanguage
      }
    });
    
    const pieChartData = [
      { title: 'success', value: insertedSlides.length, color: '#09e45f' },
      { title: 'failed', value: invalidData.length, color: '#e43109' },
    ]

    message.successMessage(`slides was imported`, messageKey);
    dispatch(importUUIDSSlice.actions.changeState({ pieChartData }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(importUUIDSSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getLanguages = (token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(importUUIDSSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { languages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`,
      headers: {
        Authorization: token
      }
    });
    const formattedLanguages = languages.map((language: any, index: number) => ({
      ...language,
      index
    }))
    dispatch(importUUIDSSlice.actions.changeState({ languages: formattedLanguages }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(importUUIDSSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};


export const selectImportUUIDSData = (state: RootState) => state.importUUIDS;

export default importUUIDSSlice.reducer;
