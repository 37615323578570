interface Item {
  id: string
  suggestedImages: any[]
  originalLabel: string
  targetLabel: string
  originalSequence: number
  targetSequence: number
  originalLanguage: string
  originalAudio: string[]
  targetAudio: string[]
  originalAudioIndex: number
  targetAudioIndex: number
  interestId: string
  topicId: string
  suggestedImage: string
  targetLanguage: string
  markdown:'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  isPublished: boolean
  notes: string
  hasImage: boolean
  hasOriginalAudio: boolean
  hasTargetAudio: boolean
}

interface BadData {
  message: string
  index: number
}

export default (items: Item[]) => {
  let badItems : BadData[] = [];
  const isValid = items.reduce((acc, item: Item, index) : boolean => {
    let message = '';

    Object.entries(item)
      .filter(([key]) => (
        key !== 'markdown' &&
        key !== 'targetLanguage' &&
        key !== 'originalLanguage' &&
        key !== 'originalAudioIndex' &&
        key !== 'targetAudioIndex'  &&
        key !== 'page'  &&
        key !== 'searchText'  && 
        key !== 'notes' && 
        key !== 'hasImage' &&
        key !== 'hasOriginalAudio' &&
        key !== 'hasTargetAudio'
        ))
      .forEach(([key, value]) => {
        if(!value) {
          message = message + ` ${key} is empty, `
        }
      });
    
    if(message)
      badItems.push({ message, index })

    const isValid = Boolean(
      item.id &&
      item.interestId &&
      Array.isArray(item.originalAudio) &&
      item.originalAudio.length &&
      item.originalAudioIndex !== null &&
      item.originalLabel &&
      item.suggestedImage &&
      item.suggestedImages &&
      Array.isArray(item.targetAudio) &&
      item.targetAudio.length &&
      item.targetAudioIndex !== null &&
      item.targetLabel &&
      item.topicId &&
      item.originalSequence &&
      item.targetSequence &&
      typeof item.isPublished === 'boolean' &&
      typeof item.notes === 'string'  &&
      typeof item.hasImage === 'boolean'&&
      typeof item.hasOriginalAudio === 'boolean'&&
      typeof item.hasTargetAudio === 'boolean'
      )
    return acc && isValid;
  }, true);

  return {
    isValid,
    badItems,
    message: isValid? 'valid data' : `${badItems.length} bad items.. some data are missing`
  };
}
