import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { message as antMessage} from 'antd';
import { v4 as uuid} from 'uuid';
import fileDownload from 'js-file-download';
import moment from 'moment';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config';

interface Language {
  id: string
  key: string
  language: string
  isPublished: boolean
}

interface Interest {
  id: string
  interest: string
}

interface Topic {
  id: string
  topic: string
  interestId: string
  topicId: string
}

interface Profile {
  id: string
  title: string
  motherLanguage: string
  targetLanguage: string[]
  createdAt: string
  updatedAt: string
  dob: string
  gender: 'male' | 'female'
}

interface Algorithim {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  isActive: boolean
  isPublished: boolean
}

interface AlgorithmsContainersLanguages {
  languageId: string
  algorithmContainerId: string
}

interface AlgorithmsContainersInterests {
  interestId: string
  algorithmContainerId: string
}

interface ContainersWithALgorithms {
  id: string
  title: string
  createdAt: string
  updatedAt: string
  isUsed: boolean
  notes: string
  isPublished: boolean
  languages: AlgorithmsContainersLanguages[]
  interests: AlgorithmsContainersInterests[]
  algorithms: Algorithim[]
};

interface LoadingStatus {
  isLoading: boolean
}

interface Presentation {
  id: string
  profileId: string
  interestId: string
  topicId: string
  targetLanguage: string
  slides: string[]
  lastWatchedDate: string
  algorithimId: string
  createdAt: string
  updatedAt: string
  algorithmContainerId: string
  algorithmContainerTitle: string
  algorithmTitle: string
  lastAppliedAlgorithm: string
}

interface Slide {
  id: string
  suggestedImages: any
  originalLabel: string
  targetLabel: string
  originalLanguage: string
  targetLanguage: string
  originalAudio: string[]
  originalAudioIndex: number
  targetAudioIndex: number
  targetAudio: string[]
  interestId: string
  topicId: string
  suggestedImage: string
  image?: any
  markdown:'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  originalSequence :number
  targetSequence: number
  originalLang?: string
  targetLang?: string
  isPublished: boolean

}

interface Template {
  id: string
  algorithimId: string
  slideIndex: number
  currentIndex: number
  isImageOn: boolean
  isOriginalLabel: boolean
  isTargetLabel: boolean
  isTargetSound: boolean
  isOriginalSound: boolean
  sleepTime: number
  originalLabelDuration: [number, number]
  targetLabelDuration: [number, number]
  targetSoundDuration: [number, number]
  originalSoundDuration: [number, number]
  imageDuration: [number, number]
}

interface PresentationToPreview {
  name: string
  algorithmtoFire: string
  slides: string[]
}

interface PreviewData {
  name: string
  algorithmtoFire: string
  slides: Slide[]
  templates: Template[]
}

interface ProfilesPresentationsState {
  languages: Language[]
  interests: Interest[]
  topics: Topic[]
  slidesToPreview: Slide[],
  profile: Profile
  isLoading: boolean
  presentations: Presentation[]
  containersWithALgorithms: ContainersWithALgorithms[]
  testResults: string[]
  testResultsModalVisibility: boolean
  previewDataModalVisibility: boolean
  presenentationsToPreview: PresentationToPreview[]
  previewData: PreviewData[]
}


const initialState: ProfilesPresentationsState = {
  languages : [],
  interests : [],
  containersWithALgorithms : [],
  topics : [],
  slidesToPreview: [],
  profile : {
    id: '',
    title: '',
    motherLanguage: '',
    targetLanguage: [],
    createdAt: '',
    updatedAt: '',
    dob: '',
    gender: 'male'
  },
  presentations: [],
  isLoading: false,
  testResults: [],
  testResultsModalVisibility: false,
  previewDataModalVisibility: false,
  presenentationsToPreview: [],
  previewData: []
};

export const profilePresentationsSlice = createSlice({
  name: 'profilePresentations',
  initialState,
  reducers: {
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeState, setLoadingStatus } = profilePresentationsSlice.actions;


const getLanguages = async () => {
  const messageKey = uuid();
  try{
    const { data : { results : languages }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`,
    });
    return languages;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};


export const getInterests = async (token: string) => {
  const messageKey = uuid();
  message.loadingMessage('loading interests ...', messageKey, 0);
  
  try{
    const { data : { results : interests }} = await axios({
      method: 'GET',
      url: `${API_URL}/interests`,
      headers: {
        Authorization: token
      }
    });
    antMessage.destroy();
    return interests;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getTopics = async (token: string) => {
  const messageKey = uuid();
  message.loadingMessage('loading topics ...', messageKey, 0);
  
  try{
    const { data : { results : topics }} = await axios({
      method: 'GET',
      url: `${API_URL}/topics`,
      headers: {
        Authorization: token
      }
    });
    antMessage.destroy();
    return topics;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

const getProfilePresentations = async (id: string, token: string) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { profilePresentations } }} = await axios({
      method: 'GET',
      url: `${API_URL}/profile/${id}/presentations`,
      headers: {
        Authorization: token
      }
    });
    return profilePresentations;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};


export const getInitialData = (id: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try{
    const [ 
      { profile, presentations },
      { languages },
      { interests },
      { topics }
    ] = await Promise.all([
      getProfilePresentations(id, token),
      getLanguages(),
      getInterests(token),
      getTopics(token)
    ]);
    const formattedPresentations = presentations.map((profilePresentation: any, index: number) => ({
        ...profilePresentation,
        lastWatchedDate: profilePresentation.lastWatchedDate ? moment(profilePresentation.lastWatchedDate) : null,
        createdAt: moment(profilePresentation.created_at).format('L'),
        updatedAt: moment(profilePresentation.updated_at).format('L'),
        index
      }));
    dispatch(profilePresentationsSlice.actions.changeState({ profile, languages, interests, topics, presentations: formattedPresentations }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    antMessage.destroy();
  }
};


export const getAlgorithmsContainerWithAlgorithms = (targetLanguage: string, id: string, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  if(!targetLanguage || !id)
      return message.infoMessage('target language are required to fetch algorithms', messageKey);
  try{
    dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { containersWithALgorithms } }} = await axios({
      method: 'GET',
      url: `${API_URL}/algorithims-containers/algorithims/with?targetLanguage=${targetLanguage}&interestId=${id}`,
      headers: {
        Authorization: token
      }
    });
    dispatch(profilePresentationsSlice.actions.changeState({ containersWithALgorithms }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getSuggestedSlides = (data: any, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  const { 
    interestId,
    originalLanguage,
    targetLanguage,
    topicId
  } = data;

  message.loadingMessage('loading slides..', messageKey, 0);
  try{
    const { data: {results : { suggestedSlides }}} = await axios({
      method: 'GET',
      url: `${API_URL}/slides/suggested/${interestId}/${topicId}/${targetLanguage}/${originalLanguage}`,
      data: { data },
      headers: {
        Authorization: token
      },
    });
    if(suggestedSlides.length < 10 ) {
      throw new Error('slides count was not fulfilled')
    } else {
      message.successMessage('suggested Slides Loaded..', messageKey);
      const slidesToPreview =  suggestedSlides
      .sort((a: any, b: any) => parseInt(a.targetSequence.replace(',', '')) - parseInt(b.targetSequence.replace(',', '')));
      dispatch(profilePresentationsSlice.actions.changeState({ slidesToPreview }));
    }
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getTestResults = (id: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('loading test results..', messageKey, 0);
  try{
    const { data: { results: { testResults, algorithmsWithSlidesToApply } }} = await axios({
      method: 'GET',
      url: `${API_URL}/profile/${id}/test`,
      headers: {
        Authorization: token
      },
    }); 
    message.successMessage('test results fetched successfully', messageKey);
    dispatch(profilePresentationsSlice.actions.changeState({ testResults, testResultsModalVisibility: true, isLoading: false, presenentationsToPreview: algorithmsWithSlidesToApply  }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const getPreviewData = (presenentationsToPreview: PresentationToPreview[], token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('loading preview data..', messageKey, 0);
  try{
    const { data: { results: { previewData } }} = await axios({
      method: 'POST',
      url: `${API_URL}/slides/templates/preview`,
      headers: {
        Authorization: token
      },
      data: { presenentationsToPreview }
    }); 
    message.successMessage('preview data fetched successfully', messageKey);
    dispatch(profilePresentationsSlice.actions.changeState({ previewData, testResultsModalVisibility: false, isLoading: false, previewDataModalVisibility: true  }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};

export const exportVideo = (presenentationsToPreview: PresentationToPreview[], token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('Exporting video.. this may take a while..', messageKey, 0);
  const presentationName = presenentationsToPreview.reduce((acc, presenation) => acc + presenation.name + ', ', '')
  try{
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/profile/presentation/export`,
      responseType: 'blob',
      headers: {
        Authorization: token
      },
      data: { presenentationsToPreview }
    }); 
    fileDownload(data, `${presentationName}.mp4`);
    message.successMessage('video was exported successfully', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};
export const addProfilePresentation = (profilePresentation: any, addedProfilePresentations: Presentation[] ,token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    message.loadingMessage('adding presentation...', messageKey, 0);
    try{
      dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { addedProfilePresentation } } } = await axios({
        method: 'POST',
        url: `${API_URL}/profiles/presentation`,
        data: { profilePresentation },
        headers: {
          Authorization: token
        }
      });
      const formatted = {
        ...addedProfilePresentation,
        lastWatchedDate: profilePresentation.lastWatchedDate ? moment(profilePresentation.lastWatchedDate) : null,
        createdAt: moment(addedProfilePresentation.created_at).format('L'),
        updatedAt: moment(addedProfilePresentation.updated_at).format('L'),
        index: addedProfilePresentations.length + 1
      }
      dispatch(profilePresentationsSlice.actions.changeState({ presentations: [...addedProfilePresentations, formatted]}));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateProfilePresentation = (id: string, profilePresentation: any, addedProfilesPresentations: Presentation[], token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    message.loadingMessage('updating profile presentation...', messageKey, 0);
    try{
      dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { updatedProfilePresentation } } } = await axios({
        method: 'PUT',
        url: `${API_URL}/profiles/presentation/${id}`,
        data: { profilePresentation },
        headers: {
          Authorization: token
        }
      });

      const formatted = {
        ...updatedProfilePresentation,
        lastWatchedDate: profilePresentation.lastWatchedDate ? moment(profilePresentation.lastWatchedDate) : null,
        createdAt: moment(updatedProfilePresentation.created_at).format('L'),
        updatedAt: moment(updatedProfilePresentation.updated_at).format('L'),
        index: addedProfilesPresentations.length + 1
      };

      const newUpdatedProfilePresenation = addedProfilesPresentations
      .map((addedProfilePresentation, index) => addedProfilePresentation.id === id ? { ...formatted, index } : addedProfilePresentation);
      
      dispatch(profilePresentationsSlice.actions.changeState({ presentations: newUpdatedProfilePresenation}));
      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };


export const deleteProfilePresentation = (profilePresentationId: string, addedProfilesPresentations: Presentation[], token: string): AppThunk => 
async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('deleting profile...', messageKey, 0);
  
  try{
    dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: true }));
    await axios.delete(`${API_URL}/profiles/presentation/${profilePresentationId}`, {
      headers: {
        Authorization: token
      }
    });
    const presentations = addedProfilesPresentations
      .filter((profilePresentation) => profilePresentation.id !== profilePresentationId)
      .map((profilePresentation, index) => ({...profilePresentation, index}));

    dispatch(profilePresentationsSlice.actions.changeState({ presentations }));
    message.successMessage('deleted successfully', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(profilePresentationsSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const selectProfilePresentations = (state: RootState) => state.profilePresentatins;

export default profilePresentationsSlice.reducer;
