import _ from 'lodash';


export default (template: any, algorithimId: string, id?: string | number) => {
  const formatted = {
    ..._.omit(template, [
      'index',
      'originalLabelDurationstart',
      'originalLabelDurationend',
      'originalSoundDurationstart',
      'originalSoundDurationend',
      'targetSoundDurationstart',
      'targetSoundDurationend',
      'imageDurationstart',
      'imageDurationend',
      'targetLabelDurationstart',
      'targetLabelDurationend',
    ]),
    ...(id && { id }),
    algorithimId,
    originalLabelDuration: [template.originalLabelDurationstart, template.originalLabelDurationend],
    targetLabelDuration: [template.targetLabelDurationstart, template.targetLabelDurationend],
    originalSoundDuration: [template.originalSoundDurationstart, template.originalSoundDurationend || 1],
    targetSoundDuration: [template.targetSoundDurationstart, template.targetSoundDurationend || 1],
    imageDuration: [template.imageDurationstart, template.imageDurationend]
  }
  return formatted;
}