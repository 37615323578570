import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { message as antMessage} from 'antd';
import { v4 as uuid} from 'uuid';
import moment from 'moment';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config';

interface Language {
  id: string
  key: string
  language: string
  isPublished: boolean
}

interface Profile {
  id: string
  title: string
  motherLanguage: string
  targetLanguage: string[]
  createdAt: string
  updatedAt: string
  dob: string
  gender: 'male' | 'female'
}

interface LoadingStatus {
  isLoading: boolean
}

interface ProfilesState {
  languages: Language[]
  profiles: Profile[]
  isLoading: boolean
}


const initialState: ProfilesState = {
  languages : [],
  profiles : [],
  isLoading: false,
};

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    changeState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const { changeState, setLoadingStatus } = profilesSlice.actions;


const getLanguages = async () => {
  const messageKey = uuid();
  try{
    const { data : { results : languages }} = await axios({
      method: 'GET',
      url: `${API_URL}/languages`,
    });
    return languages;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};
const getProfiles = async (token: string) => {
  const messageKey = uuid();
  
  try{
    const { data : { results: { profiles } }} = await axios({
      method: 'GET',
      url: `${API_URL}/profiles`,
      headers: {
        Authorization: token
      }
    });
    const formattedProfiles = profiles.map((profile: any, index: number) => ({
      ...profile,
      dob: moment(profile.dob),
      createdAt: moment(profile.created_at).format('L'),
      updatedAt: moment(profile.updated_at).format('L'),
      index
    }));
    return formattedProfiles;
  } catch(err) {
    message.errorMessage(err, messageKey);
  }
};


export const getInitialData = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try{
    const [ profiles, { languages }] = await Promise.all([getProfiles(token), getLanguages()]);
    dispatch(profilesSlice.actions.changeState({ profiles, languages }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    antMessage.destroy();
  }
};


export const addProfile = (profile: any, addedProfiles: Profile[] ,token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    if(profile.motherLanguage === profile.targetLanguage) return message.infoMessage('choose different languages.', messageKey);
    
    message.loadingMessage('adding profile...', messageKey, 0);
    try{
      dispatch(profilesSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { addedProfile } } } = await axios({
        method: 'POST',
        url: `${API_URL}/profiles`,
        data: { profile },
        headers: {
          Authorization: token
        }
      });
      const formatted = {
        ...addedProfile,
        dob: moment(addedProfile.dob),
        createdAt: moment(addedProfile.created_at).format('L'),
        updatedAt: moment(addedProfile.updated_at).format('L'),
        index: addedProfiles.length + 1
      }
      dispatch(profilesSlice.actions.changeState({ profiles: [...addedProfiles, formatted]}));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(profilesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateProfile = (id: string, profile: any, addedProfiles: Profile[], token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    message.loadingMessage('updating profile...', messageKey, 0);
    try{
      dispatch(profilesSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { updatedProfile } } } = await axios({
        method: 'PUT',
        url: `${API_URL}/profiles/${id}`,
        data: { profile },
        headers: {
          Authorization: token
        }
      });


      const formatted = {
        ...updatedProfile,
        dob: moment(updatedProfile.dob),
        createdAt: moment(updatedProfile.created_at).format('L'),
        updatedAt: moment(updatedProfile.updated_at).format('L'),
      }
      const newUpdatedAlgorithms = addedProfiles
        .map((addedProfile, index) => addedProfile.id === id ? { ...formatted, index } : addedProfile);

      dispatch(profilesSlice.actions.changeState({ profiles: newUpdatedAlgorithms}));
      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(profilesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };


export const deleteProfile = (profileId: string, addedProfiles: Profile[], token: string): AppThunk => 
async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('deleting profile...', messageKey, 0);
  
  try{
    dispatch(profilesSlice.actions.setLoadingStatus({ isLoading: true }));
    await axios.delete(`${API_URL}/profiles/${profileId}`, {
      headers: {
        Authorization: token
      }
    });
    const profiles = addedProfiles
      .filter((profile) => profile.id !== profileId)
      .map((profile, index) => ({...profile, index}));

    dispatch(profilesSlice.actions.changeState({ profiles }));
    message.successMessage('deleted successfully', messageKey);
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(profilesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const selectProfiles = (state: RootState) => state.profiles;

export default profilesSlice.reducer;
