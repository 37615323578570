import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import {  changeCurrentUser } from '../../app/userSlice';
import { v4 as uuid} from 'uuid';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

interface LoginCredentials {
  email: string
  password: string
}

interface LoadingStatus {
  isLoading: boolean
}
interface LoginState {
  email: string
  password: string
  isLoading: boolean
}

const initialState: LoginState = {
  email: '',
  password: '',
  isLoading: false
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeLoginData: (state, action: PayloadAction<LoginCredentials>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
  },
});

export const loginApi = (loginCredentials: LoginCredentials): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(loginSlice.actions.setLoadingStatus({ isLoading: true }));
    message.loadingMessage('logging in ...', messageKey, 0);
    const { data : { results } } = await axios({
      method: 'post',
      url: `${API_URL}/login`,
      data: loginCredentials
    });
    message.successMessage(`welcome`, messageKey);
    dispatch(changeCurrentUser(results));
    
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(loginSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const { changeLoginData } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLoginData = (state: RootState) => state.login;

export default loginSlice.reducer;
